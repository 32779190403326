
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_EVENTS } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import PageContent from 'Components/layout/panel/PageContent';
import StyledComponent from 'Components/core/StyledComponent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import EventsList from 'Components/trainer/events/List';
import EventsCalendar from 'Components/trainer/events/Calendar';

export const TAB_EVENTS_CALENDAR = 'eventsCalendar';

export default class TrainerEvents extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-trainer-events"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_EVENTS.path),
                        label: 'Wydarzenia',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Wydarzenia"
                        tabs={[{
                            key: 'future',
                            label: 'Nadchodzące wydarzenia',
                            children: (
                                <EventsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        orderBy: 'future',
                                        excludedCategory: 'private',
                                    }}
                                    allAdeptEvents={false}
                                />
                            ),
                        }, {
                            key: 'past',
                            label: 'Historia wydarzeń',
                            children: (
                                <EventsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        orderBy: 'past',
                                    }}
                                    allAdeptEvents={false}
                                />
                            ),
                        }, {
                            key: TAB_EVENTS_CALENDAR,
                            label: 'Kalendarz wydarzeń',
                            children: (
                                <EventsCalendar
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
