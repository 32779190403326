import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADEPT_EVENT_CYCLE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

export default class AdeptEventCyclesList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        cyclicEvents: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    state = {};

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { actions } = this.props;
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (
            JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject) ||
            JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)  //eslint-disable-line react/destructuring-assignment
        ) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { cyclicEvents, location, history } = this.props;

        return (
            <StyledComponent
                className="adept-events-cycles-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={cyclicEvents}
                    queryConfig={this.getQueryConfig()}
                    styleVersion={2}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.startAt && element.endAt ? getFormattedDate(element.startAt, 'date') + ' - ' + getFormattedDate(element.endAt, 'date') : 'Brak dat'}
                            additionals={[{
                                name: element.originEvent?.userOffer.productCategory?.slug === 'masaz'
                                    ? 'Twój terapeuta'
                                    : 'Twój trener',
                                imageSrc: element.originEvent?.lead.avatar || require('Theme/images/placeholders/image.jpg'),
                                value: element.originEvent?.lead && `${element.originEvent?.lead.name} ${element.originEvent?.lead.surname}`  || 'Brak',
                            }, {
                                name: 'Lokalizacja',
                                iconSrc: require('Theme/images/icons/location.svg'),
                                value: element.originEvent?.location && element.originEvent?.location.name || 'Brak',
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADEPT_EVENT_CYCLE.path, { id: element.originEvent?.id }),
                                controlStyle: 'hollow',
                            }]}
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}
