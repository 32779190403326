
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import EventsList from 'Components/adept/events/List';
import EventCyclesList from 'Components/adept/events/EventCycle/List';

export default class AdminUserOffers extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-adept-events"
                styles={require('./styles')}
            >
                <TabNavigation
                    location={location}
                    history={history}
                    headline="Twoje wydarzenia"
                    tabs={[{
                        key: 'future',
                        label: 'Nadchodzące wydarzenia',
                        children: (
                            <EventsList
                                location={location}
                                history={history}
                                groups={true}
                                predefinedQuery={{
                                    orderBy: 'future',
                                }}
                            />
                        ),
                    }, {
                        key: 'past',
                        label: 'Historia wydarzeń',
                        children: (
                            <EventsList
                                location={location}
                                history={history}
                                groups={true}
                                predefinedQuery={{
                                    orderBy: 'past',
                                }}
                                header={{
                                    visible: false,
                                }}
                            />
                        ),
                    // }, {
                    //     key: 'eventCycles',
                    //     label: 'Twoje cykle',
                    //     children: (
                    //         <EventCyclesList
                    //             location={location}
                    //             history={history}
                    //         />
                    //     )
                    }]}
                />
            </StyledComponent>
        );
    }
}
